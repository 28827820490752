import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Loading } from './Loading';
// Components
const App = lazy(() => import('./App'));
const Error404 = lazy(() => import('./Error404'));
const Timeline = lazy(() => import('./Timeline/Timeline'));
const FishPalace = lazy(() => import('./FishPalace/FishPalace'));
const Pixelever = lazy(() => import('./Pixelever/Pixelever'));
const Bday = lazy(() => import('./Bday/Bday'));
const Simon = lazy(() => import('./Simon/Simon'));
const HitList = lazy(() => import('./HitList/HitList'));
const ImageViewer = lazy(() => import('./ImageViewer/ImageViewer'));
const CustomRSS = lazy(() => import('./CustomRSS/CustomRSS'));
const Dots = lazy(() => import('./Dots/Dots'));

export const SiteNav = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
            <Route path="/timeline" component={Timeline} />
            <Route path="/fishpalace" component={FishPalace} />
            <Route path="/pixelever" component={Pixelever} />
            <Route path="/bday" component={Bday} />
            <Route path="/simon" component={Simon} />
            <Route path="/hitlist" component={HitList} />
            <Route path="/imageviewer" component={ImageViewer} />
            <Route path="/rss" component={CustomRSS} />
            <Route path="/dots" component={Dots} />
            <Route path="/" component={App} exact />
            <Route component={Error404} />
        </Switch>
      </Suspense>
    </Router>
  );
}
